import React, { useRef } from "react";
import emailjs from "emailjs-com";
import "./Contact.scss";
import Header from "../../components/Header/Header";
import { MdCall } from "react-icons/md";
import Logo from "../../assets/Goxpress_logo1.png";

const Contact = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_USER_ID
			)
			.then(
				(result) => console.log(result.text),
				(error) => console.log(error.text)
			);
		e.target.reset();
	};

	return (
		<div>
			<Header
				title="Contact"
				text="Take the complexity out of customs Freight Solutions with customs brokerage services."
			/>

			<div className="app__contact">
				<img src={Logo} alt="logo" width="100px" />
				<h1>We’d love to hear from you</h1>
				<p>
					Please send your enquiry to info@goxpress.com, or contact your local
					specialists using the contact details below.
				</p>
			</div>

			<div className="app__contact-address">
				<div>
					<h4>Head Office</h4>
					<p>
						9750 Meadowlark Gardens Ct, Vienna, Va <br />
						22182 USA Washington DC
					</p>
					<p>Email: info@goxpress.com</p>
				</div>
				<div>
					<h4>Branch Office</h4>
					<p>M34-3SQ Whitworth street,west Manchester, United Kingdom</p>
					<p>Email: info@goxpress.com</p>
				</div>
			</div>
			<a href="tel:+15642253747">
				<button className="btn-phone">
					<MdCall /> +15642253747
				</button>
			</a>
			<div className="app__contact-form">
				<div className="form-text">
					<p>Have any Question?</p>
					<h2>
						If you would like to know more about our services, please contact us
						using this form
					</h2>
				</div>

				<form
					ref={form}
					onSubmit={sendEmail}
					className="app__footer-form app__flex">
					<div className="app__flex">
						<input
							type="text"
							className="p-text"
							placeholder="Your Name"
							name="name"
						/>
					</div>
					<div className="app__flex">
						<input
							type="email"
							className="p-text"
							placeholder="Your Email"
							name="email"
						/>
					</div>
					<div>
						<textarea
							name="message"
							className="p-text"
							placeholder="Your Message"></textarea>
					</div>
					<button className="btn-submit">Submit</button>
				</form>
			</div>
		</div>
	);
};

export default Contact;
