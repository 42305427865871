import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "./SliderComp.scss";
import sliderImg1 from "../../assets/slide01.jpg";
import sliderImg2 from "../../assets/slide02.jpg";
import sliderImg3 from "../../assets/slide03.jpg";

const heroPart = [
  {
    imageSrc: sliderImg1,
    title: "Fast, Secure, Reliable Shipping Services",
    desc: "Trust Goxpress to get your package delivered quickly and securely, anywhere in the world.",
    btnText: "Contact Us",
  },
  {
    imageSrc: sliderImg2,
    title: "Delivery Services for Urgent Shipments",
    desc: "Need to get your package delivered urgently? We offer same-day delivery services for both local and international shipments.",
    btnText: "Contact Us",
  },
  {
    imageSrc: sliderImg3,
    title: "Customized Shipping Solutions for Businesses",
    desc: " Let us create customized shipping solutions that meet your specific requirements.",
    btnText: "Contact Us",
  },
];

const SliderComp = () => {
  const settings = {
    mobileFirst: true,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    cssEase: "linear",
  };
  return (
    <Slider {...settings}>
      {heroPart.map((item, index) => (
        <div className="app__slider-container" key={index}>
          <img src={item.imageSrc} alt={item.desc} />
          <div className="app__slider-textOverlay">
            <h2>{item.title}</h2>
            <p className="p-text">{item.desc}</p>
            <Link to="/contact">
              <button>{item.btnText}</button>
            </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderComp;
