import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Logistics from "./pages/Logistics/Logistics";
import Contact from "./pages/Contact/Contact";
import Services from "./pages/Services/Services";
import Tracking from "./pages/Tracking/Tracking";
import Home from "./pages/Home/Home";
import Faq from "./pages/Faq/Faq";

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/logistics" element={<Logistics />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/tracking" element={<Tracking />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
