import React from "react";
import HomeCards from "../../components/HomeCards/HomeCards";
import SliderComp from "../../components/Slider/SliderComp";
import { TrackingSeg } from "../../components/TrackingSegment/TrackingSeg";
import GalleryImg from "../../components/Gallery/GalleryImg";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Counter from "../../components/Counter/Counter";
import Banner from "../../components/Banner/Banner";
import Testimonial from "../../components/Testimonial/Testimonial";

const Home = () => {
  return (
    <div className="app__home">
      <SliderComp />
      <TrackingSeg />
      <HomeCards />
      <GalleryImg />
      <ServiceSection />
      <Counter />
      <Banner />
      <Testimonial />
    </div>
  );
};

export default Home;
