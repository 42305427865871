import React from "react";
import "./ServiceSection.scss";
import sideImg from "../../assets/sideImage.jpg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  return (
    <section className="app__serviceSection">
      <div className="app__serviceSection-lhs">
        <h4>Services</h4>
        <h2>Quality delivered as standard</h2>
        <motion.div
          whileInView={{
            x: [-100, 0],
            opacity: [0, 1],
          }}
          translate={{
            duration: 7.0,
          }}
          className="service-list-container"
        >
          <p>Forwarding</p>
          <p>Outsourcing</p>
          <p>Technology</p>
          <p>Supply Chain</p>
        </motion.div>
        <Link to="/services">
          <button>All Services</button>
        </Link>
      </div>
      <div className="app__serviceSection-rhs">
        <img src={sideImg} alt="side" />
      </div>
    </section>
  );
};

export default ServiceSection;
