import React from "react";
import section from "../../assets/section-bg.jpg";
import "./LogisticHeader.scss";
import { Link } from "react-router-dom";

const LogisticHeader = () => {
  return (
    <div className="app__logistic">
      <img src="" alt="" />
      <h1>Reliable USA & Ireland Transport Logistics Since 2003</h1>

      <div className="logistic__container">
        <div className="text-content">
          <p>
            At Goxpress, we understand that getting your goods to your customers
            on time and in full is your top priority. That's why we offer a
            comprehensive range of transport logistics solutions to give you the
            flexibility to send consignments of different sizes without the need
            for multiple providers. With so many options available, you can
            trust us to deliver your consignment, regardless of its size or
            destination. Our experienced transport team has over 120 years of
            combined experience and is always available to provide expert advice
            on choosing the right solution for your specific needs. Whether you
            need same-day delivery or standard ground transportation, we've got
            you covered. We take pride in offering customized transport
            logistics solutions that are tailored to your unique requirements.
            Our advanced tracking technology ensures that you can track your
            consignment in real-time, giving you complete visibility throughout
            the entire transport process. At Goxpress, we're committed to
            providing fast, reliable, and secure transport logistics solutions
            that exceed your expectations. Trust us to deliver your goods safely
            and on time, every time. Contact us today to learn more about our
            transport logistics services and how we can help you streamline your
            supply chain operations.
          </p>
        </div>
        <img src={section} alt="/" />
      </div>

      <div className="logistic-contact">
        <h2>Contact us today!</h2>
        <div>
          <p>Contact us today for your airfreight requirements</p>
          <Link to="/contact">
            <button>contact us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LogisticHeader;
