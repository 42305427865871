import React from "react";
import "./Faq.scss";
import Faq from "react-faq-component";
import Header from "../../components/Header/Header";

const data = {
  title: "FAQ",
  rows: [
    {
      title: "HELP! I CAN’T FIND MY PACKAGE!",
      content:
        "We want to help! Please click here to view the status of your package. If you still need further assistance, fill out the form on the tracking page with your tracking number and contact details. After you submit the form, you will receive an email with a case number for your reference. Please allow us up to 48 hours to investigate so we can respond to you with the latest information about your package and next steps.",
    },
    {
      title: "I DON’T HAVE A TRACKING NUMBER. WHAT DO I DO?",
      content:
        "We recommend contacting your retailer to request a tracking number.",
    },
    {
      title:
        "WHAT DOES “ATTEMPTED” MEAN? NO ONE CAME TO MY HOUSE, AND I WAS HOME ALL DAY.",
      content:
        "When a delivery is marked as “attempted” it means that the package was loaded onto a vehicle, but we were unable to deliver it to the final destination. This could be for several reasons, including issues with package safety, the delivery address, or accessing the delivery location. If your delivery is attempted, we will secure your package at our facility and re-attempt delivery the following business day. If there is an issue with the address, we will work with you and/or the retailer to update the address.",
    },
    {
      title:
        "WHAT DO I DO IF MY PACKAGE IS MARKED DELIVERED BUT I CAN’T FIND IT?",
      content:
        "We're sorry for the inconvenience and are happy to help you. We will open an internal investigation to try to locate your package. In order to do so, please visit the tracking page and fill out the form with the required details. After you submit the form, you will receive an email with a case number for your reference. Please allow us up to 48 hours to investigate so we can respond to you with the latest information about your package and next steps.",
    },
    {
      title: "WHEN DOES GOXPRESS DELIVER?",
      content:
        "Goxpress delivers packages to residences Monday through Friday between 8 a.m. and 9 p.m. and until 5 p.m. to business addresses",
    },
    {
      title: "DOES GOXPRESS DELIVER ON WEEKENDS?",
      content:
        "Select service areas provide deliveries Saturdays 8 a.m. to 9 p.m. and Sundays 8 a.m. to 9 p.m.",
    },
    {
      title: "CAN I PICK UP MY PACKAGE AT ONE OF YOUR FACILITIES?",
      content:
        "You may request to pick up your package at a local delivery facility by calling our customer service department: +18172903643",
    },
    {
      title: "CAN I CHANGE MY DELIVERY ADDRESS ONCE IT HAS SHIPPED?",
      content:
        "For security reasons, your seller will need to approve changes to your delivery address. Please contact your seller",
    },
    {
      title: "I NO LONGER WANT TO RECEIVE MY PACKAGE. WHAT CAN I DO?",
      content:
        "Please notify us by filling out our contact form. If tracking says your package is out for delivery, you may leave a note on the door requesting that your package not be delivered",
    },
    {
      title:
        "WHAT ARE Goxpress’S CUSTOMER SERVICE CALL CENTER HOURS OF OPERATION?",
      content:
        "Monday-Friday: 8a.m.-9:30p.m Saturday: 8a.m.-8p.m.Our customer service call center is closed on Christmas Day, New Year’s Day, and Thanksgiving Day",
    },
  ],
};

const FaqSec = () => {
  return (
    <>
      <Header title="FAQ" />

      <div className="app__faq">
        <h2>Frequently Asked Questions About Goxpress Courier Service</h2>

        <Faq
          data={data}
          styles={{
            bgColor: "white",
            titleTextColor: "#48482a",
            rowTitleColor: "#78789a",
            rowTitleTextSize: "large",
            rowContentColor: "#48484a",
            rowContentTextSize: "16px",
            rowContentPaddingTop: "10px",
            rowContentPaddingBottom: "10px",
            rowContentPaddingLeft: "50px",
            rowContentPaddingRight: "150px",
            arrowColor: "black",
          }}
        />
      </div>
    </>
  );
};

export default FaqSec;
