import React from "react";
import "./Footer.scss";
import logo from "../../assets/Goxpress_logo1.png";
import { Link } from 'react-router-dom';

const date = new Date();
let newDate = date.getFullYear();

const Footer = () => {
  return (
    <>
      <section className="app__footer">
        <div className="app__container">
          <div className="first_container">
            <img src={logo} alt="Footer-logo" />
            <p>
              We are a courier company committed to serving our numerous
              customers. <br /> We deliver quality and our watch-word is
              excellence. <br />
              With many years of experience and excellently trained staff,{" "}
              <br /> your packages are always safe with us.
            </p>
          </div>
          <div className="second_container">
            <h5>Company</h5>
            <ul>
               <Link to='/'><li>Home</li></Link>
             <Link to='/logistics'><li>Logistics</li></Link>
              <Link to='/services'><li>Services</li></Link>
            </ul>
          </div>
          <div className="third_container">
            <h5>Contact</h5>
            <ul>
              <Link to='/faq'><li>Faq</li></Link>
              <Link to='/contact'><li>Contact</li></Link>
              <Link to='/'><li>Careers</li></Link>
            </ul>
          </div>
        </div>

        <hr />
        <span>{newDate} Courier Service || All Rights Reserved.</span>
      </section>
    </>
  );
};

export default Footer;
