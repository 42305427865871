import React, { useRef } from "react";
import "./Tracking.scss";
import Logo from "../../assets/Goxpress_logo1.png";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from "qrcode.react";

const Tracking = () => {
	const { state } = useLocation();
	const data = state.result && state.result.data;
	const status = data && data.status;
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	if (!state || !state.result || !data) {
		return (
			<div className="error-div">
				<h2>Invalid Tracking Id!</h2>
			</div>
		);
	}

	return (
		<section className="app__tracking-info-cont">
			<div className="tracking-info-container" ref={componentRef}>
				<div className="tracking-info-header">
					<div className="company-logo">
						<img src={Logo} alt="logo" width="100px" />
					</div>
					<div className="tracking-status">
						<div
							className={`status-dot ${
								status === "on Hold" ? "red-blinking" : "green-blinking"
							}`}></div>
						<span className="status">{status?.toUpperCase()}</span>
					</div>
					<div className="tracking-id">Tracking ID: {data?.trackId}</div>
				</div>
				<span
					style={{
						display: "block",
						paddingTop: "3rem",
						margin: "auto",
						fontSize: "1rem",
					}}>
					{data?.comments}
				</span>
				<div className="tracking-info-body">
					<div className="tracking-info-section contact-info">
						<h3>Sender Information</h3>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Name:</div>
							<div className="tracking-info-value">{data?.sendersName}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Contact:</div>
							<div className="tracking-info-value">{data?.sendersContact}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Email:</div>
							<div className="tracking-info-value">{data?.sendersEmail}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Address:</div>
							<div className="tracking-info-value">{data?.sendersAddress}</div>
						</div>
					</div>
					<div className="tracking-info-section contact-info">
						<h3>Receiver Information</h3>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Name:</div>
							<div className="tracking-info-value">{data?.recieverName}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Contact:</div>
							<div className="tracking-info-value">{data?.recieverContact}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Email:</div>
							<div className="tracking-info-value">{data?.recieverEmail}</div>
						</div>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Address:</div>
							<div className="tracking-info-value">{data?.recieverAddress}</div>
						</div>
					</div>
					<div className="tracking-info-section">
						<h3>Shipment Information</h3>
						<div className="tracking-info-row">
							<div className="tracking-info-label">Item Description:</div>
							<div className="tracking-info-value">{data?.itemDesc}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Product Name:</div>
							<div className="tracking-info-value">{data?.productName}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Weight:</div>
							<div className="tracking-info-value">{data?.weight}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Shipment Mode:</div>
							<div className="tracking-info-value">{data?.shipmentMode}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Shipment Type:</div>
							<div className="tracking-info-value">{data?.shipmentType}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Payment Mode:</div>
							<div className="tracking-info-value">{data?.paymentMode}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Quantity:</div>
							<div className="tracking-info-value">{data?.qty}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Total Freight:</div>
							<div className="tracking-info-value">{data?.totalFreight}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Original Country:</div>
							<div className="tracking-info-value">{data?.originalCountry}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Destination:</div>
							<div className="tracking-info-value">{data?.destination}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Consignment Movement:</div>
							<div className="tracking-info-value">{data?.customStatus}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">
								Estimated Delivery Date:
							</div>
							<div className="tracking-info-value">
								{data?.estimatedDeliveryDate}
							</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Sent Date/Time:</div>
							<div className="tracking-info-value">{data?.sentDateTime}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Pickup Time:</div>
							<div className="tracking-info-value">{data?.pickupTime}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Pickup Date:</div>
							<div className="tracking-info-value">{data?.pickupDate}</div>
						</div>
						<hr />
						<div className="tracking-info-row">
							<div className="tracking-info-label">Comments:</div>
							<div className="tracking-info-value">{data?.comments}</div>
						</div>
						<hr />
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						paddingBottom: "2rem",
					}}>
					<QRCodeSVG
						value="https://goxpressway.com/"
						size={200}
						imageSettings={{
							src: `${Logo}`,
							width: 100,
						}}
					/>
				</div>

				<button onClick={handlePrint}>Click to Print</button>
			</div>
		</section>
	);
};

export default Tracking;
