import React from "react";
import { ImQuotesLeft } from "react-icons/im";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import logo1 from "../../assets/logo01.jpg";
import logo2 from "../../assets/logo02.jpg";
import logo3 from "../../assets/logo03.jpg";
import logo4 from "../../assets/logo04.jpg";
import "./Testimonial.scss";

const content = [
  {
    text: "My goods came in as required and in perfect condition. The only complain would be not having any complaints. Excellent and well trained staff.",
    name: "Alex Olevnik",
    role: "Sales Team Acrostish",
  },
  {
    text: "As a first time importer from the Far East, the process can be quite daunting. GoExpress has provided exceptional service and support right through exceptional the process.",
    name: "Steve Macholnad",
    role: "Manager Smart Move Ltd",
  },
  {
    text: "Transporting heavy duty goods just became much easier with GoXpress. Exceptional handling of fragile goods and prompt delivery. I highly recommend them.",
    name: "Jack Abraham",
    role: "Director of Envanto LLC",
  },
];

const brands = [
  {
    src: logo1,
    name: "logo1",
  },
  {
    src: logo2,
    name: "logo2",
  },
  {
    src: logo3,
    name: "logo3",
  },
  {
    src: logo4,
    name: "logo4",
  },
];

const Testimonial = () => {
  const settings = {
    mobileFirst: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  return (
    <section className="app__testimonial">
      <Slider {...settings}>
        {content.map((item, i) => (
          <div key={i} className="lhs">
            <ImQuotesLeft />
            <p>{item.text}</p>
            <h3>{item.name}</h3>
            <span>{item.role}</span>
          </div>
        ))}
      </Slider>
      <div className="rhs">
        <h1>Trusted By Some The World's Companies for over 180 years</h1>
        {brands.map((brand, index) => (
          <motion.div
            key={index}
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
          >
            <img src={brand.src} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Testimonial;
