import React from "react";
import "./Banner.scss";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section className="app__banner">
      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1],
        }}
        transition={{
          duration: 0.7,
        }}
        className="banner_content"
      >
        <h2>
          Go<span>Xpress</span>
        </h2>
        <p>We’re one of the USA’s leading shipping and logistics providers.</p>
        <div className="btn-div">
          <Link to="/contact">
            <button className="banner-btn">Get a Quote</button>
          </Link>
          <Link to="/services">
            <button className="banner-btn">View our Services</button>
          </Link>
        </div>
      </motion.div>
    </section>
  );
};

export default Banner;
