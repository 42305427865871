import React, { useState } from "react";
import "./Navbar.scss";
import { motion } from "framer-motion";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { Link } from "react-router-dom";
import GoExpress from "../../assets/Goxpress_logo1.png";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <Link to="/">
          <img src={GoExpress} alt="logo" />
        </Link>
      </div>
      <ul className="app__navbar-links">
        <li className="app__flex p-text">
          <Link to="/">Home</Link>
        </li>
        <li className="app__flex p-text">
          <Link to="/logistics">Logistics</Link>
        </li>
        <li className="app__flex p-text">
          <Link to="/services">Services</Link>
        </li>
        <li className="app__flex p-text">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="app__flex p-text">
          <Link to="/faq">Faq</Link>
        </li>
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{
              x: [300, 0],
            }}
            transition={{ duration: 1, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links" onClick={() => setToggle(false)}>
              <li className="app__flex p-text">
                <Link to="/">Home</Link>
              </li>
              <li className="app__flex p-text">
                <Link to="/logistics">Logistics</Link>
              </li>
              <li className="app__flex p-text">
                <Link to="/services">Services</Link>
              </li>
              <li className="app__flex p-text">
                <Link to="/contact">Contact</Link>
              </li>
              <li className="app__flex p-text">
                <Link to="/faq">Faq</Link>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
