import React, { useState } from "react";
import "./TrackingSeg.scss";
import track from "../../assets/slider3.jpg";
import banner from "../../assets/slider2.jpg";
import { BiSearchAlt2 } from "react-icons/bi";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../apiConfig";
import axios from "axios";

export const TrackingSeg = () => {
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = useState({
    id: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setTrackingNumber({ [e.target.name]: e.target.value });
  };

  const handleTrack = async (e, query) => {
    e.preventDefault();
    setLoading(true);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/getid`,
        { id: trackingNumber.id },
        options
      );
      const result = await response.data;
      setLoading(false);
      navigate("/tracking", { state: { result } });
    } catch (error) {
      setLoading(false);

      // Check if the error is a server error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data); // Log the server error response data
        console.log(error.response.status); // Log the server error status code
        console.log(error.response.headers); // Log the server error response headers

        // Handle the server error based on the status code or response data
        // For example, display an error message to the user
        alert(`Server error: ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        // Handle the request error, e.g., display a network error message
        alert("Network error. Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        // Handle other types of errors
        alert("An unexpected error occurred. Please try again later.");
      }
    }
  };

  return (
    <section className="app__trackSection-container">
      <div className="text">
        <p>
          GoXpress specializes in <span>Logistics Facilities </span>
          and provides constant technical support for essential services.
        </p>
      </div>

      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1],
        }}
        transition={{ duration: 0.7 }}
        className="app__trackingSection-tracking"
      >
        <img src={track} alt="track-img" />

        <div className="rhs">
          <h2>Track your Shipment</h2>
          <p>Example: 12345</p>
          <form onSubmit={handleTrack}>
            <div>
              <input
                name="id"
                id="id"
                value={trackingNumber.id}
                onChange={handleChange}
                placeholder="Enter Tracking Number"
                type="text"
              />
            </div>
            <button type="submit">
              <BiSearchAlt2 fontSize="1rem" />{" "}
              {!loading ? "Search" : "Loading..."}
            </button>
          </form>
        </div>
      </motion.div>

      <div className="app__tracking-hero">
        <motion.div
          whileInView={{
            x: [-100, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 0.7,
          }}
          className="app__tracking-hero-lhs"
        >
          <h2>Worldwide Logistics, Air Freight Forwarding, Road Haulage.</h2>
          <p>
            We understand the challenges that businesses face in today's rapidly
            evolving market. That's why we offer a comprehensive range of
            logistics services that are designed to streamline your supply chain
            and increase your operational efficiency. From warehousing and
            inventory management to transportation and distribution, we've got
            you covered.
          </p>
          <Link to="/logistics">
            <button className="btn-track">Discover All Solutions</button>
          </Link>
        </motion.div>

        <motion.img
          whileInView={{
            y: [-100, 0],
            opacity: [0, 1],
          }}
          transition={{
            duration: 1,
          }}
          src={banner}
          alt="hero-img"
        />
      </div>
    </section>
  );
};
