import React from "react";
import "./Header.scss";

const Header = ({ title, text }) => {
  return (
    <div className="app__header">
      <div>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Header;
