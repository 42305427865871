import React from "react";
import "./HomeCards.scss";
import { motion } from "framer-motion";
import { TfiPackage } from "react-icons/tfi";
import { FaTruckMoving } from "react-icons/fa";
import { GiCargoCrate } from "react-icons/gi";
import { Link } from "react-router-dom";

const cardDeets = [
  {
    icon: TfiPackage,
    title: "Leadership",
    desc: "Experts who have extensive, hands-on experience in supply chain management",
    btnText: "Connect with an expert",
  },
  {
    icon: FaTruckMoving,
    title: "Technology",
    desc: "Innovative and varied use of technology on the road, ocean, railways, in the air.",
    btnText: "Connect with an expert",
  },
  {
    icon: GiCargoCrate,
    title: "Solution",
    desc: "Global leaders in intermodal, less-than-truckload, supply chain management.",
    btnText: "Connect with an expert",
  },
];

const HomeCards = () => {
  return (
    <div className="app__homeCards">
      {cardDeets.map((item, i) => (
        <motion.div
          whileInView={{
            y: [100, 0],
            opacity: [0, 1],
          }}
          transition={{ duration: 1 }}
          key={i}
          className="app__homeCards-card"
        >
          <item.icon />
          <h2>{item.title}</h2>
          <p>{item.desc}</p>
          <Link to="/contact">
            <button>{item.btnText}</button>
          </Link>
        </motion.div>
      ))}
    </div>
  );
};

export default HomeCards;
