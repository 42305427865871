import React from "react";
import CountUp from "react-countup";
import "./Counter.scss";

const Counter = () => {
  return (
    <section className="app__counter">
      <div className="app__counter-container">
        <div>
          <h2>
            <CountUp end={40} duration={3.75} />
          </h2>
          <p>Operating Centers</p>
        </div>
        <div>
          <h2>
            <CountUp end={120} duration={3.75} />
          </h2>
          <p>Countries Worldwide</p>
        </div>
        <div>
          <h2>
            <CountUp end={40} duration={3.75} />K
          </h2>
          <p>Logistics Professionals</p>
        </div>
        <div>
          <h2>
            <CountUp end={400} duration={3.75} />K
          </h2>
          <p>Containers of Freight</p>
        </div>
        <div>
          <h2>
            <CountUp end={35} duration={3.75} />K
          </h2>
          <p>Project Delivery Vehicles</p>
        </div>
        <div>
          <h2>
            <CountUp end={20} duration={3.75} />l
          </h2>
          <p>Sq.ft of Warehousing</p>
        </div>
      </div>
    </section>
  );
};

export default Counter;
