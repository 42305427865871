import React from "react";
import "./Gallery.scss";
import Slide01 from "../../assets/slide01.jpg";
import Slider2 from "../../assets/slider2.jpg";
import { motion } from "framer-motion";
import logo from "../../assets/Goxpress_logo1.png";
import { Link } from "react-router-dom";

const GalleryImg = () => {
  return (
    <div className="app__gallery-container">
      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1],
        }}
        transition={{
          duration: 0.7,
        }}
        className="gallery-content"
      >
        <img src={logo} alt="Logo" />
        <h2>Global supply chain solutions</h2>
        <p>Dedicated specialists taking care of your products</p>
      </motion.div>
      <motion.div
        whileInView={{
          x: [-100, 0],
          opacity: [0, 1],
        }}
        transition={{
          duration: 0.7,
        }}
        className="app__image-gallery-container"
      >
        <div className="gallery-items">
          <div className="gallery-cont">
            <img src={Slide01} alt="" height="300px" width="500px" />
            <div className="text-overlay">
              <p>Solutions</p>
              <h2>Transportation</h2>
              <Link to="/services">
                <button>Discover All</button>
              </Link>
            </div>
          </div>
          <div className="gallery-cont">
            <img src={Slider2} alt="" height="300px" width="500px" />
            <div className="text-overlay">
              <p>Solutions</p>
              <h2>Global 4PL</h2>
              <Link to="/services">
                <button>Discover All</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="gallery-items">
          <div className="gallery-cont">
            <img src={Slide01} alt="" height="500px" width="200px" />
            <div className="text-overlay">
              <p>Solutions</p>
              <h2>Food and Beverage</h2>
              <Link to="/services">
                <button>Discover All</button>
              </Link>
            </div>
          </div>
          <div className="gallery-cont">
            <img src={Slider2} alt="" height="300px" width="500px" />
            <div className="text-overlay">
              <p>Solutions</p>
              <h2>Consumer Packaged Goods</h2>
              <Link to="/services">
                <button>Discover All</button>
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default GalleryImg;
