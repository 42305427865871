import React from "react";
import Header from "../../components/Header/Header";
import level1 from "../../assets/level1.jpg";
import level2 from "../../assets/level2.jpg";
import level3 from "../../assets/level3.jpg";
import HomeCards from "../../components/HomeCards/HomeCards";
import "./Services.scss";
import logo from "../../assets/Goxpress_logo1.png";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div>
      <Header
        title="Services"
        text="Take the complexity out of customs Freight Solutions with customs brokerage services"
      />

      <div className="app__service-firstPart">
        <div className="header">
          <img src={logo} alt="logo" />
          <h2>Reliable Transport Logistics Since 2003</h2>
        </div>

        <div className="text-flex">
          <p>
            Welcome to Goxpress, your premier courier service for all your
            shipping needs. Our commitment is to provide you with fast, secure,
            and reliable shipping services that are tailored to meet your unique
            needs. We pride ourselves on being the go-to courier service for
            individuals and businesses alike. At Goxpress, we understand that
            every package is important, which is why we handle each shipment
            with the utmost care and attention to detail. Our team of
            experienced couriers is well-trained to handle all types of
            packages, from small documents to large shipments. With our advanced
            tracking system, you can monitor your shipment in real-time, giving
            you peace of mind knowing that your package is on its way. We offer
            a range of services to meet your specific needs, including same-day
            delivery, overnight shipping, and international shipping. Our
            extensive network of partners allows us to offer competitive rates
            for all our services, without compromising on quality. Our customer
            service team is available 24/7 to assist you with any questions or
            concerns you may have. We understand that shipping can be stressful,
            which is why we strive to make the process as easy and
            straightforward as possible. Our team is always ready to assist you
            with any special requests, such as packing and labeling your
            package, to ensure that it arrives at its destination safely and
            securely.
          </p>
          <p>
            At Goxpress, we believe in providing our customers with the best
            possible service, which is why we are always looking for ways to
            improve and exceed expectations. Trust us to deliver your package
            quickly, securely, and with the highest level of professionalism.
            Contact us today to learn more about our services and how we can
            help you with all your shipping needs.
          </p>
        </div>
      </div>

      <HomeCards />

      <div className="level-one">
        <div className="text">
          <h2>Same-Day Delivery Services</h2>
          <p>
            At Goxpress, we understand that sometimes you need your package to
            be delivered as quickly as possible. That's why we offer same-day
            delivery services for both local and international shipments. Our
            team of experienced couriers is dedicated to picking up your package
            and delivering it to its destination within the same day, no matter
            how urgent your shipment may be. With our advanced tracking system,
            you can monitor your package in real-time, giving you peace of mind
            knowing that your package is on its way. Our same-day delivery
            service is perfect for urgent business documents, medical supplies,
            and time-sensitive materials that require immediate attention. Trust
            us to get your package delivered quickly and securely, even on the
            same day.
          </p>
          <Link to="/faq">
            <button>Discover All</button>
          </Link>
        </div>
        <img src={level1} alt="" />
      </div>

      <div className="level-two">
        <img src={level2} alt="lvl2" />
        <div className="txt-cont">
          <h2>International Shipping Services</h2>
          <p>
            At Goxpress, we understand the challenges that come with
            international shipping, including customs clearance and regulatory
            compliance. That's why we offer international shipping services that
            are tailored to meet your specific needs. Our team of experts is
            well-versed in international shipping regulations and will ensure
            that your package arrives at its destination on time and in perfect
            condition. We offer a range of international shipping options,
            including air freight and ocean freight, to suit your specific
            requirements. Our advanced tracking system enables you to track your
            package in real-time, giving you complete visibility throughout the
            entire shipping process.
          </p>
          <ul>
            <li>Dedicated secure facilities</li>
            <li>Customs clearances</li>
            <li>Online inventory reporting</li>
            <li>Full order management & back office integration</li>
          </ul>
        </div>
      </div>

      <div className="level-three">
        <div className="txt-cont-2">
          <h2>Customized Solutions for Businesses</h2>
          <p>
            At Goxpress, we understand that businesses have unique shipping
            needs that require customized solutions. That's why we offer
            personalized shipping solutions tailored to meet the needs of your
            business. Our team of experts will work with you to develop a
            shipping plan that meets your specific requirements and budget. We
            offer a range of services, including customized packaging, labeling,
            and tracking solutions, to ensure that your package arrives at its
            destination safely and securely. With our advanced technology, you
            can track your package in real-time, giving you complete visibility
            throughout the entire shipping process. Trust us to provide you with
            customized shipping solutions that meet your unique needs and exceed
            your expectations.
          </p>
        </div>
        <img src={level3} alt="lvl3" />
      </div>
    </div>
  );
};

export default Services;
