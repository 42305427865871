import React from "react";
import Header from "../../components/Header/Header";
import LogisticHeader from "../../components/LogisticHeader/LogisticHeader";

const Logistics = () => {
  return (
    <>
      <Header
        title={"Logistics"}
        text={
          "Take the complexity out of customs Freight Solutions with customs brokerage services"
        }
      />
      <LogisticHeader />
      {/* <LogisticCard /> */}
    </>
  );
};

export default Logistics;
